<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="page-wrapper login">
      <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
          <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
            <div class="d-flex align-items-center container p-0">
              <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                <a href="javascript:void(0)"
                   class="page-logo-link press-scale-down d-flex align-items-center">
                  <img src="media/logo.svg" class="logo-edutalk" alt="Edutalk Tư Vấn"
                       aria-roledescription="logo">
                  <span class="page-logo-text mr-1"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 ml-auto">
                  <div class="card p-4 rounded-plus bg-faded">
                    <h1 class="fw-400 mb-3 d-sm-block">
                      Nhập mã xác nhận
                    </h1>
                    <form id="js-login" class="form" @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
                      <label class="form-label email-content mb-3">Chúng tôi đã gửi 1 mã xác thực đến email của bạn(<a :href="`mailto:${getUserEmail}`" target="_blank" class="text-decoration-none">{{getUserEmail}}</a>). Vui lòng kiểm tra email và nhập mã xác thực tại đây</label>
                      <div class="form-group form-otp">
                        <div class="input_code d-flex justify-content-between align-items-center">
                          <CodeInput style="width: 100%" :fieldWidth="50" :fieldHeight="50" :loading="false" class="input" ref="code_input" v-on:change="onChange" v-on:complete="onComplete" />
                        </div>
                        <span slot="footer" class="dialog-footer">
                          <ValidationProvider vid="code" name="Mã xác thực" rules="required|min:6"
                                              v-slot="{ errors, classes }">
                          <input type="hidden" ref="code" name="code" class="form-control form-control-lg"
                                 :class="classes"
                                 v-model=codeOTP>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                        </span>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-lg-12 pr-lg-1 my-2">
                          <button type="submit" id="login-submit" ref="login-submit"
                                  class="btn btn-primary btn-block btn-lg waves-effect waves-themed" :disabled="verifyButton">
                            Tiếp tục
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="isLoading"></span>
                          </button>
                        </div>
                        <div class="col-lg-12 mt-3 text-center justify-content-edutalk">
                          <vue-countdown :time="60000" @end="onCountdownEnd" v-slot="{ totalSeconds }">
                            <a href="#" v-if="counting"> Thử lại sau<span> ( {{ totalSeconds }}s)</span></a>
                            <a href="#" @click="sendOTP" v-else> Gửi lại mã xác nhận </a>
                          </vue-countdown>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col col-md-6 col-lg-7 hidden-sm-down">
                  <lottie-animation :path="'media/lottie/english-teacher.json'" />
                </div>
              </div>
              <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                          target="_blank">edutalk.edu.vn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<!-- Load login custom page styles -->

<script>
import {mapGetters} from "vuex";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import CodeInput from "vue-verification-code-input";
import VueCountdown from '@chenfengyuan/vue-countdown';
import {USER_VERIFY_OTP, RESEND_OTP} from "@/core/services/store/auth.module";
export default {
  name: "edutalk-otp",
  components: {
    LottieAnimation,
    CodeInput,
    VueCountdown
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      state: "signin",
      check_error: false,
      counting: false,
      verifyButton : false,
      isLoading : false,
      codeOTP : ''
    };
  },
  computed : {
    ...mapGetters(['getUserCode', 'getUserEmail'])
  },
  methods: {
    onSubmit() {
      let vm = this;
      this.verifyButton = true;
      this.isLoading = true;
      let code = this.codeOTP;
        vm.$store.dispatch(USER_VERIFY_OTP, { otp : code, user_id : this.getUserCode })
            .then((response) => {
              this.$router.push('new-password').then( ()=> {
                vm.$message({ type: 'success', message: `${response.message}`, showClose: true });
              });
            })
            .catch((e) => {
              if (e.data){
                this.$refs.form.setErrors({code: [e.data.message]})
              }
              Object.values(this.$refs.code_input.$refs).forEach(input =>{
                input[0].style.cssText = "border-color: #fd3995 !important; width: 50px ;height: 50px";
              } );
              this.verifyButton = false;
              this.isLoading = false;
        });
    },
    onChange() {
      this.verifyButton = true;
    },
    onComplete(v) {
      this.verifyButton = false;
      this.codeOTP = v;
    },
    startCountdown: function () {
      this.counting = true;
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
    sendOTP: function () {
      this.startCountdown();
      this.$store.dispatch(RESEND_OTP,
          {user_id : this.getUserCode})
          .then(() => {
            this.$message({
              type: "success",
              message: 'Gửi mã xác thực thành công!',
              showClose: true
            });
          })
    },
  }
};
</script>
<style scoped>
.email-content {
  color: #747474;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

</style>
<style>

.react-code-input {
  display: flex !important;
  margin: 0;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 414px) {
  .react-code-input input {
    width: 37px !important;
    height: 37px !important;
    font-size: 12px;
  }
}

</style>
